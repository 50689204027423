<template>
  <div class="news">
    <h2>Wiadomości:</h2>
    <div @click="showMessage(item.title)" v-for="item in news" :key="item.id" class="news__message">
      <svg class="news__icon" viewBox="0 0 18.801 12.131">
        <g id="Group_38" data-name="Group 38" transform="translate(-0.5 -0.5)">
          <rect id="Rectangle_152" data-name="Rectangle 152" class="cls-1" width="17.801" height="11.131" transform="translate(1 1)"/>
          <path id="Path_5" data-name="Path 5" class="cls-1" d="M18.8,1,9.9,8.773,1,1"/>
          <line id="Line_30" data-name="Line 30" class="cls-1" y1="5.026" x2="6.975" transform="translate(1 7.106)"/>
          <line id="Line_31" data-name="Line 31" class="cls-1" x1="6.975" y1="5.026" transform="translate(11.826 7.106)"/>
        </g>
      </svg>
      <span class="news__show-more">Pokaż <span v-if="activeMessage !== item.title">więcej</span><span v-if="activeMessage === item.title">mniej</span></span>
      <span class="news__date">{{item.created_at}}</span>
      <p :class="['news__title', {'news__title--active': item.title === activeMessage}]">{{item.title}}
      </p>
      <p :class="['news__content', {'news__content--active': item.title === activeMessage}]" v-html="item.content">
      </p>
    </div>
    <div v-if="!news || news.length === 0">
      <p>Brak wiadomości dla tej edycji. Zaglądaj do tej zakładki, żeby być na bieżąco!</p>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/utils/http-common';

export default {
  data: () => {
    return {
      news: null,
      activeMessage: '',
    };
  },
  methods: {
    showMessage: function(message) {
      if (this.activeMessage === message) {
        this.activeMessage = '';
      } else {
        this.activeMessage = message;
      }
    },
  },
  created() {
    HTTP.get(`api/articles`).then((response) => {
      this.news = response.data.data.reverse();
    }).catch((e) => {
      // console.error(e);
    });
  },
};
</script>
<style lang="scss" scoped>
.news {

  &__icon {
    width: 15px;
    position: absolute;
    left: -30px;
    top: 25px;
    fill: none;
    stroke: #d9d9d9;
    stroke-miterlimit: 10;
  }

  &__message {
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    padding-right: 100px;

    &:hover {
      color: $primary-color;

      .news__icon {
        stroke: $primary-color;
      }
    }
  }

  &__show-more {
    position: absolute;
    top: 22px;
    right: 20px;
    cursor: pointer;
    font-size: rem-calc(12px);
  }

  &__date {
    display: block;
    font-size: rem-calc(12px);
    color: $light-gray-color;
    color: #888888;
  }

  &__title {

    &--active {
      font-weight: bold;
    }
  }

  &__content {
    display: none;

    &--active {
      display: inline;
    }
  }
}
</style>


